<template>
<div class="elem-feed radius box-shadow">
  <div class="head-feed">
      <div class="pic-name">
        <div class="profile-pic"
          :class="posterPicture ? '' : 'empty'"
          :style="posterPicture !== '' ? `background-image:url(${posterPicture})` : ''"
        >
        </div>
        <div class="profile-user">
          <div class="name-user">
              {{ownerString}}
          </div>
          <div class="time-post">
            {{calcDateFromNow(this.elem.createdAt)}}
          </div>
        </div>
      </div>
      <div class="cont-btn-feed">
        <div
          class="expand-section btn-green small radius-small"
          @click="expand"
          v-if="!this.expanded && this.oversized">
          Show More
        </div>
        <div class="follow-button-cont">
          <follow-button :type="postOwnerType" :accountId="elem.owner._id"/>
        </div>
      </div>
  </div>
  <div class="content-feed" ref="feedContent">
    <div class="excerp-post">
      <p class="inner-excerpt">
        {{ elem.text }}
      </p>
    </div>
    <div class="visual-feed radius-small box-shadow"
      v-if="elem.attachments && elem.attachments.length > 0"
      :class="elem.attachments[0].type === 'Audio' ? 'audio-visual' : ''"
    >
      <!-- <img
        :src="elem.attachments[0].data"
        :alt="decodeURI(elem.attachments[0].data.split('/')[elem.attachments[0]
          .data.split('/').length-1])"
      > -->
      <img
      v-if="elem.attachments[0].type === 'Image'"
        :src="elem.attachments[0].data"
        :alt="decodeURI(elem.attachments[0].data.split('/')[elem.attachments[0]
          .data.split('/').length-1])"
      >
      <video
        v-if="elem.attachments[0].type === 'Video'"
        controls
        class="preview" :src="elem.attachments[0].data">
      </video>
      <audio controls class="doc audio"
        v-else-if="elem.attachments[0].type === 'Audio'"
        :src="elem.attachments[0].data"
      ></audio>
      <embed class="preview pdf-preview"
        v-else-if="elem.attachments[0].type === 'Application' || elem.attachments[0].type === 'Pdf'"
        :src="elem.attachments[0].data"
      />
    </div>
  </div>
  <div class="footer-feed">
    <div class="left-footer">
      <div class="elem-lightbulb" @click="likePost">
          <div class="picto" :class="{ liked : elem.youLiked }">
              <img :src="require('@/assets/pictos/picto_lightbulb_white.svg')">
              <img class="liked" :src="require('@/assets/pictos/picto_lightbulb_darkpurple.svg')">
          </div>
          <div class="label">
              <span class="tlabel">{{ $t("recommend") }}</span>
              <span v-if="elem.likes.length > 0">({{elem.likes.length}})</span>
          </div>
        </div>
      <!-- <div class="elem-footer">
      </div> -->
    </div>
    <div class="right-footer">
      <social-share
        v-if="elem.attachments && elem.attachments.length > 0"
        :description="`${elem.text}`"
        :url="`/post/${elem._id}`"
        :title="`Check this post on Quasting`"
        :media="decodeURI(elem.attachments[0].data.split('/')[elem.attachments[0]
          .data.split('/').length-1])"
      />
      <social-share
        v-else
        :description="`${elem.text}`"
        :url="`/post/${elem._id}`"
        :title="`Check this post on Quasting`"
      />
    </div>
  </div>
</div>
</template>

<script>
import calcDateFromNow from '@/modules/mixins/calcDateFromNow';
import SocialShare from '@/components/UniversalComponents/SocialMedia/socialShareList.vue';
import FollowButton from '@/components/UniversalComponents/Feed/following/followButton.vue';

export default {
  name: 'feedCard',
  props: {
    elem: Object,
  },
  data() {
    return {
      expanded: false,
      isMounted: false,
    };
  },
  components: {
    SocialShare,
    FollowButton,
  },
  mixins: [calcDateFromNow],

  computed: {
    oversized() {
      if (this.isMounted) {
        return this.$refs.feedContent && this.$refs.feedContent.clientHeight > 50;
      }
      return false;
    },
    ownerString() {
      if (this.elem.owner.name.firstName) {
        return `${this.elem.owner.name.firstName} ${this.elem.owner.name.lastName}`;
      }
      return this.elem.owner.name;
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    owner() {
      return this.elem.owner;
    },
    comments() {
      return this.elem.comments || [];
    },
    shares() {
      return this.elem.shares || [];
    },
    bookmarks() {
      return this.elem.bookmarks || [];
    },
    feedId() {
      if (this.$store.getters['auth/type'] === 'Talent') {
        return this.$store.state.user._id;
      }
      return this.$store.state.company.companyInfo._id;
    },
    isLiked() {
      return this.elem.likes.findIndex((like) => like.owner._id === this.feedId) > -1;
    },
    posterPicture() {
      if (this.owner.profilePicture) {
        return this.owner.profilePicture;
      }
      if (this.owner.companyPicture) {
        return this.owner.companyPicture;
      }
      return '';
    },
    postOwnerType() {
      if (this.owner.__t) {
        return 'User';
      }
      return 'Company';
    },

  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    redirect() {
      //   this.$router.push(`/talent/${this.elem._id}`);
    },
    likePost() {
      this.$store.dispatch('feed/likePost', this.elem._id).then((data) => {
        this.elem.likes = data.likes;
      });
    },
    expand() {
      this.$router.push(`/post/${this.elem._id}`);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.visual-feed{
  &.audio-visual{
    box-shadow: none !important;
  }
}
.cont-btn-feed{
  display: flex;
  .btn-green{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.follow-button-cont{
  // position: absolute;
  // right: 2vh;
}
.pdf-preview{
  width: 80%;
  height: 30vh;
  margin-left: 10%;
}
</style>
