<template>
  <div class="job-description">
    <div class="radius contentbox marginB box-shadow" v-if="!loading">
      <div class="subtitle-page">Job Description</div>
      <div class="inner-info padding" v-html="offerData.description">
      </div>
    </div>
    <loading class="radius contentbox marginB box-shadow" v-else/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/utilityPartials/_loading.vue';

export default {
  name: 'JobDescription',
  components: {
    Loading,
  },
  data() {
    return {
      company: {},
      loading: true,
    };
  },
  mounted() {
    this.$api.get(`companyInfo/${this.offerData.company._id || this.offerData.company}`).then((res) => {
      this.company = res.data;
      this.loading = false;
    });
  },
  computed: {
    ...mapState('offer', {
      offerData: (state) => state.offerData,
    }),
    calcMasculin() {
      return `${this.company.parity * 100}%`;
    },
    calcFeminin() {
      return `${100 - (this.company.parity * 100)}%`;
    },
  },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.cont-resume-info-company{
  .elem-info-sup{
    .title-sup{
      font-size: $fnt-14;
    }
    .data-sup{
      font-size: $fnt-subtitle;
    }
    .cont-parity{
      .inner-parity{
        font-size: $fnt-subtitle;
      }
    }
  }
}

.contentbox{
  overflow: hidden;
  .subtitle-page{
    width: 100%;
    font-size: 1.8vh;
    padding: 1vh 1.5vh;
    color: #ffffff;
    background-color: $logo_color;
  }
  .padding{
    padding: 0vh 1vh 2vh 1vh;
  }
}

.contentbox{
  margin-bottom: 2vh;
  &:last-child{
    margin-bottom: 0;
  }
}

.job-description{
  width: 100%;
}
.inner-info{
  margin-bottom: 40px;
}

.separ-hori{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  .dot{
    height: 12px;
    width: 12px;
    background-color: $green_color;
    border-radius: 50%;
    &:nth-child(1){
      height: 14px;
      width: 14px;
      margin: 0 5px;
    }
    &:nth-child(3){
      height: 10px;
      width: 10px;
      margin: 0 5px;
    }
  }
}

.cont-info-sup{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  .elem-info-sup{
    flex: 1;
    background-color: $logo_color;
    color: #ffffff;
    padding: 25px 0;
    margin-left: 15px;
    height: 66px;
    line-height: 1;
    font-weight: 600;
    &:first-child{
      margin-left: 0;
    }
    .title-sup{
      font-family: 'MontserratBold';
      font-size: $classic_fs;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .data-sup{
      font-family: 'MontserratBlack';
      font-size: 40px;
    }
    .cont-parity{
      display:flex;
      width: 90%;
      margin: 0 auto;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-family: 'MontserratBlack';
      font-size: 35px;
      .inner-parity{
        width: 50%;
        position: relative;
        img{
          width: 55%;
          opacity: 0.3;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }
}
</style>
