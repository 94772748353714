<template>
  <div class="inner-process">
    <!-- {{steps}} -->
    <div class="step-cont" v-for="(step) in steps"
      :key="step._id">
      <div class="step box-shadow"

        :class="{
          current: step.status === 'Current',
          approved: step.status === 'Approved',
          rejected: step.status === 'Rejected'
        }"
        v-tooltip.top-center="{
          content: contentToolTip(step),
          delay: {
            show: 0,
            hide: 0,
          }
        }"
      >
        <img class=""
            :src="require(`@/assets/pictos/${assetString(step)}.svg`)"
          >
      </div>
      <div class="connect-line" />
    </div>

  </div>
</template>

<script>
import calcDateFromNow from '@/modules/mixins/calcDateFromNow';

const picto = {
  'Video Meeting': 'videomeeting',
  'Live Code': 'videocoding',
  'Coding Test': 'coding',
  'Phone Call': 'phone',
};
const color = {
  Initial: 'gray',
  Approved: 'darkpurple',
  Current: 'white',
  Rejected: 'white',
};
export default {
  name: 'ProcessProgress',
  props: ['steps'],
  mixins: [calcDateFromNow],
  methods: {
    contentToolTip(elem) {
      if (elem.meeting && elem.meeting.event && elem.meeting.eventAccepted === true && elem.status === 'Current' && elem.meeting.event.date) {
        return this.calcDateFromNow(elem.meeting.event.date.start);
      }
      if (elem.status === 'Rejected' || elem.status === 'Approved') {
        return elem.status;
      }
      return elem.action;
    },
    assetString(step) {
      return `picto_${picto[step.action] || 'allmedia'}_${color[step.status] || 'white'}`;
    },

  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .inner-process{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding: 1vh 0vw 0vh 0vh;
    .step-cont{
      display: flex;
      flex-direction: row ;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      // max-width: 4vw;
      flex: 1;
      &:last-child{
        .connect-line{
          display: none;
        }
        max-width: 3vw;
      }

    }
    .connect-line{
        height: 1px;
        background-color: $logo_color;
        border-bottom: 1px solid #ffffff;
        left: 0%;
        position: relative;
        flex: 2;
        // width: 100%;

      }
    .step{
      position: relative;
      z-index: 1;
      margin: 0;
      max-width: 3vw;
      width: 100%;
      border-radius: 50%;
      background-color: #f8f8f8;

      overflow: visible;
      flex: 6;
      border-width: 0px;
      &:last-child{
        margin-right: 0;
      }
      &:first-child{
        &::before{
          display: none;
        }
      }

      &.rejected{
        background-color: $logo_color_dark;
        p{
          color: #ffffff;
        }
      }
      &.approved{
        background-color: $green_color;
        p{
          color: #ffffff;
        }
      }
      &.current{
        background-color: $logo_color;
        p{
          color: #ffffff;
        }
      }
      .infos{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 80%;
        font-size: $fnt-14;
        line-height: 1;
        font-weight: 600;
      }
      p{
        width: 80%;
        margin: 0 auto;
        font-size: $micro_fs;
        text-align: center;
      }
    }
  }
</style>
