export default [
  {
    text: 'HTML',
    picto: 'technos/html',
  },
  {
    text: 'CSS',
    picto: 'technos/css',
  },
  {
    text: 'JavaScript',
    picto: 'technos/js',
  },
  {
    text: 'C++',
    picto: 'technos/c_plus_plus',
  },
  {
    text: 'C#',
    picto: 'technos/c_sharp',
  },
  {
    text: 'Clojure',
    picto: 'technos/clojure',
  },
  {
    text: '.Net',
    picto: 'technos/dotnet',
  },
  {
    text: 'Gulp',
    picto: 'technos/gulp',
  },
  {
    text: 'MongoDB',
    picto: 'technos/mongoDB',
  },
  {
    text: 'MySQL',
    picto: 'technos/mySQL',
  },
  {
    text: 'NestJS',
    picto: 'technos/nestJS',
  },
  {
    text: 'NodeJS',
    picto: 'technos/nodeJS',
  },
  {
    text: 'Perl',
    picto: 'technos/perl',
  },
  {
    text: 'PHP',
    picto: 'technos/php',
  },
  {
    text: 'Python',
    picto: 'technos/python',
  },
  {
    text: 'Rails',
    picto: 'technos/rails',
  },
  {
    text: 'Redis',
    picto: 'technos/redis',
  },
  {
    text: 'Ruby',
    picto: 'technos/ruby',
  },
  {
    text: 'Scala',
    picto: 'technos/scala',
  },
  {
    text: 'WebPack',
    picto: 'technos/webpack',
  },
  {
    text: 'Vue',
    picto: 'technos/vue',
  },
  {
    text: 'VueX',
    picto: 'technos/vue',
  },
  {
    text: 'Java',
    picto: 'technos/java',
  },
  {
    text: 'Go',
    picto: 'technos/go',
  },
  {
    text: 'Wordpress',
    picto: 'technos/wordpress',
  },
  {
    text: 'Apache',
    picto: 'technos/apache',
  },
  {
    text: 'Mocha',
    picto: 'technos/mocha',
  },
  {
    text: 'Redux',
    picto: 'technos/redux',
  },
  {
    text: 'Django',
    picto: 'technos/django',
  },
  {
    text: 'Gatsby',
    picto: 'technos/gatsby',
  },
  {
    text: 'JQuery',
    picto: 'technos/jquery',
  },
  {
    text: 'Kotlin',
    picto: 'technos/kotlin',
  },
  {
    text: 'TypeScript',
    picto: 'technos/typescript',
  },
  {
    text: 'Sequelize',
    picto: 'technos/sequelize',
  },
  {
    text: 'Haskell',
    picto: 'technos/haskell',
  },
  {
    text: 'Lua',
    picto: 'technos/lua',
  },
  {
    text: 'Swift',
    picto: 'technos/swift',
  },
  {
    text: 'R',
    picto: 'technos/r',
  },
  {
    text: 'Dart',
    picto: 'technos/dart',
  },
  {
    text: 'GitHub',
    picto: 'technos/github',
  },
  {
    text: 'GitLab',
    picto: 'technos/gitlab',
  },
  {
    text: 'Rust',
    picto: 'technos/rust',
  },
  {
    text: 'AWS',
    picto: 'technos/aws',
  },
  {
    text: 'Unity',
    picto: 'technos/unity',
  },
  {
    text: 'K',
    picto: 'technos/k',
  },
];
