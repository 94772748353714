<template>
  <div class="modal">
    <div class="green-circle-button close-button" @click="closeModal">
      <span>X</span>
    </div>
    <div class="content-modal">
      <div class="title">{{this.prompt}}</div>
      <div class="selectApplication radius box-shadow contentbox">
        <input type="radio" id="a" :value="answerA" v-model="selected">
        <label for="a">{{answerA}}</label>
      </div>
      <div class="selectApplication radius box-shadow contentbox">
        <input type="radio" id="b" :value="answerB" v-model="selected">
        <label for="b">{{answerB}}</label>
      </div>
      <div @click="submit" class="btn-green radius">Submit</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DialogModal',
  props: ['prompt', 'answerA', 'answerB', 'callback'],
  data() {
    return {
      selected: this.answerA,
    };
  },
  methods: {
    submit() {
      this.callback(this.selected);
    },
    closeModal() {
      this.$modal.hide(this.modalName ? this.modalName : 'dialog-modal');
    },
  },

};
</script>

<style lang="scss" scoped>
  .selectApplication{
    cursor: pointer;
    margin: 1vh 0;
    padding: 1vh 0.5vh ;
    a, label{
      cursor: pointer;
    }

  }
  .btn-green{
    margin-top: 1vh;
    margin-left: 0;
  }
</style>
