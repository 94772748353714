<template>
  <div class="name-tag">
    <div class="picture"><img
      :src="user.profilePicture" /></div>
    <div class="texts">
      <div class="name">{{nameString(user)}}</div>
      <div class="date" v-if="applied">{{calcUpdatedFromNow()}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserNameTag',
  props: ['user', 'applied'],
  methods: {
    nameString(elem) {
      return `${elem.name.firstName} ${elem.name.lastName}`;
    },
    calcUpdatedFromNow() {
      const now = new Date(Date.now());
      const postDate = new Date(Date.parse(this.applied));
      const timeElapsed = now.getTime() - postDate.getTime();
      const differenceInDays = timeElapsed / (1000 * 3600 * 24);
      let textResult = 'error';
      if (differenceInDays < 1) {
        textResult = 'today';
      } else if (differenceInDays < 2) {
        textResult = 'yesterday';
      } else if (differenceInDays > 1 && differenceInDays <= 30) {
        textResult = `${Math.round(differenceInDays)} days ago`;
      } else if (differenceInDays > 30 && differenceInDays <= 60) {
        textResult = `${Math.round(differenceInDays / 30)} month`;
      } else {
        textResult = `${Math.round(differenceInDays / 30)} months`;
      }
      return textResult;
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
.name-tag{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  position: relative;
  .picture{
    width: 2.5vw;
    height: 2.5vw;
    background-color: $logo_color_dark;
    border-radius: 50%;
    margin-right: 1vh;
    overflow: hidden;
    position: relative;
    img{
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%)

    }
  }
  .texts{
    padding-top:5px;
    width:calc(100% - 3.5vw);
    .name{
      font-size: 1.8vh;
      font-weight: 600;
      margin-bottom: 0.5vh;
    }
    .date{
      color: #999999;
      font-size: 1.2vh;
    }
  }
}

</style>
