<template>
  <div class="company-description">
    <div class="radius contentbox marginB box-shadow" v-if="!loading">
      <div class="subtitle-page">
        Company Description
      </div>
      <div class="conpanyInfos">
        <div class="inner-info padding" v-html="company.description">
        </div>
      </div>

    </div>
    <loading class="radius contentbox marginB box-shadow" v-else/>
    <div class="cont-resume-info-company"  v-if="!loading">
      <div class="elem-info-sup box-shadow radius" v-if="company.yearFounded">
        <div class="title-sup">Established</div>
        <div class="data-sup">{{company.yearFounded}}</div>
      </div>
      <div class="elem-info-sup box-shadow radius" v-if="company.numEmployees">
        <div class="title-sup">People</div>
        <div class="data-sup">{{company.numEmployees}}</div>
      </div>
      <div class="elem-info-sup box-shadow radius" v-if="company.parity">
        <div class="title-sup">Parity</div>
        <div class="cont-parity">
          <div class="inner-parity left-parity">
            <img :src="require('@/assets/pictos/picto_masculin_purple.svg')">
            {{calcMasculin}}
          </div>
          <div class="inner-parity right-parity">
            <img :src="require('@/assets/pictos/picto_feminin_purple.svg')">
            {{calcFeminin}}
          </div>
        </div>
      </div>
      <div class="elem-info-sup box-shadow radius" v-if="company.averageAge">
        <div class="title-sup">Average Age</div>
        <div class="data-sup">{{company.averageAge}}</div>
      </div>
    </div>
    <loading class="radius contentbox marginB box-shadow" v-else/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/utilityPartials/_loading.vue';

export default {
  name: 'JobDescription',
  components: {
    Loading,
  },
  data() {
    return {
      company: {},
      loading: true,
    };
  },
  mounted() {
    this.$api.get(`companyInfo/${this.offerData.company._id || this.offerData.company}`).then((res) => {
      this.company = res.data;
      this.loading = false;
    });
  },
  methods: {
    seeprofileCompany() {
      window.open(`${window.location.origin}/company/${this.offerData.company._id}`, '_blank');
    },
  },
  computed: {
    ...mapState('offer', {
      offerData: (state) => state.offerData,
    }),
    calcMasculin() {
      return `${this.company.parity * 100}%`;
    },
    calcFeminin() {
      return `${100 - (this.company.parity * 100)}%`;
    },
  },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.cont-resume-info-company{
  width: 100%;
  .elem-info-sup{
    width: calc(calc(100% - 16.7vh) / 3);
    margin-right: 2vh;
    margin-bottom: 2vh;
    background-color: transparent;
    border: 0.1vh solid $logo_color;
    color: $logo_color;
    overflow: hidden;
    &:nth-child(3){
      margin-right: 0;
    }
    .title-sup{
      font-size: 2.2vh;
      color: $text_color;
      font-family: "MontserratBold";
    }
    .data-sup{
      width: 100%;
      margin-top: 0.5vh;
      font-family: "MontserratBold";
      text-align: center;
      font-size: 4vh;
    }
    .cont-parity{
      .inner-parity{
        font-size: 4vh;
        img{
          width: 80%;
          opacity: 0.3;
          transform: translateX(-95%) translateY(-50%);
        }
        &:nth-child(2){
          img{
            transform: translateX(5%) translateY(-50%);
          }
        }
      }
    }
  }
}

.contentbox{
  overflow: hidden;
  .subtitle-page{
    width: calc(100% - 3vh);
    font-size: 1.8vh;
    padding: 1vh 1.5vh;
    color: #ffffff;
    background-color: $logo_color;
  }
  .padding{
    padding: 0vh 1vh 2vh 1vh;
  }
}

.contentbox{
  margin-bottom: 2vh;
  &:last-child{
    margin-bottom: 0;
  }
}

.company-description{
  width: 100%;
}
.inner-info{
  margin-bottom: 40px;
}

.separ-hori{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  .dot{
    height: 12px;
    width: 12px;
    background-color: $green_color;
    border-radius: 50%;
    &:nth-child(1){
      height: 14px;
      width: 14px;
      margin: 0 5px;
    }
    &:nth-child(3){
      height: 10px;
      width: 10px;
      margin: 0 5px;
    }
  }
}

.cont-info-sup{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  .elem-info-sup{
    flex: 1;
    background-color: $logo_color;
    color: #ffffff;
    padding: 25px 0;
    margin-left: 15px;
    height: 66px;
    line-height: 1;
    font-weight: 600;
    &:first-child{
      margin-left: 0;
    }
    .title-sup{
      font-family: 'MontserratBold';
      font-size: $classic_fs;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .data-sup{
      font-family: 'MontserratBlack';
      font-size: 40px;
    }
    .cont-parity{
      display:flex;
      width: 90%;
      margin: 0 auto;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-family: 'MontserratBlack';
      font-size: 35px;
      .inner-parity{
        width: 50%;
        position: relative;
        img{
          width: 55%;
          opacity: 0.3;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }
}
</style>
