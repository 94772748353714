<template>
  <div class="job-description radius contentbox box-shadow">
    <div class="subtitle-page">We are looking for</div>
    <div
      v-html="offerData.desiredProfileDescription"
      v-if="offerData.desiredProfileDescription !== ''"
      class="padding"
    ></div>
    <!-- {{offerData.desiredProfileDescription}} -->
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'JobTalent',
  computed: {
    ...mapState('offer', {
      offerData: (state) => state.offerData,
    }),
  },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.contentbox{
  overflow: hidden;
  .subtitle-page{
    width: 100%;
    font-size: 1.8vh;
    padding: 1vh 1.5vh;
    color: #ffffff;
    background-color: $logo_color;
  }
  .padding{
    padding: 0vh 1vh 2vh 1vh;
  }
}
.job-description{
  width: 100%;
}
</style>
