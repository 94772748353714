/* eslint-disable global-require */
export default [
  {
    name: 'facebook',
    iconRoute: require('@/assets/pictos/picto_fb_white.svg'),
  },
  {
    name: 'twitter',
    iconRoute: require('@/assets/pictos/picto_twitter_white.svg'),
  },
  {
    name: 'linkedin',
    iconRoute: require('@/assets/pictos/picto_linkedin_white.svg'),
  },
];
