<template>
  <div id="offer-info" class="full-width">
    <div class="flex-container flex-full no-padding no-margin">
      <div class="elem-flex">
        <div class="title-page">
          {{offerData.name}}
        </div>
      </div>
    </div>
    <div class="inner-container">
      <div class="flex-container main-cont">
        <div class="offer-resume">
          <div class="full-width">
            <JobResume></JobResume>
          </div>
        </div>
        <div class="offer-settings">
          <div class="select-group">
            <div v-for="section in optionSections"
              :key="section"
              class="option"
              @click="() => {currentSection = section; goTop();}"
              :class="{selection: currentSection === section}"
              >
              {{section}}
              <div class="underline" v-if="section === currentSection" />
            </div>
          </div>
          <div class="content-cont" id="settings-cont" >
            <template v-if="currentSection === 'Job Description'">
              <div id="listsetting" class="">
                <JobDescription></JobDescription>
                <div class="separ-section"></div>
                <JobTalent></JobTalent>
              </div>
            </template>
            <template v-if="currentSection === 'Our Company' ">
              <div class="">
                <CompanyDesc></CompanyDesc>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SimpleNav from '@/components/UniversalComponents/SimpleNavIntern.vue';
import PerfectScrollbar from 'perfect-scrollbar';
import JobDescription from '@/components/UniversalComponents/Offer/OfferDisplay/JobDescription.vue';
import JobTalent from '@/components/UniversalComponents/Offer/OfferDisplay/JobTalent.vue';
import JobResume from '@/components/UniversalComponents/Offer/OfferDisplay/JobResume.vue';
import CompanyDesc from '@/components/UniversalComponents/Offer/OfferDisplay/companyDesc.vue';

export default {
  name: 'OfferDisplay',
  components: {
    // SimpleNav,
    JobResume,
    JobTalent,
    JobDescription,
    CompanyDesc,
  },
  props: ['offerId'],
  computed: {
    offerData() {
      if (this.offerId) {
        return this.$store.getters['company/getOfferById'](this.offerId);
      }
      return this.$store.state.offer.offerData;
    },
  },
  data() {
    return {
      loading: true,
      optionSections: ['Job Description', 'Our Company'],
      currentSection: 'Job Description',
      talent: {},
      company: {},
      navIndex: 1,
      componentList: [
        {
          name: 'Description',
          comp: JobDescription,
          picto: 'picto_jobdesc',
        },
        {
          name: 'Talent',
          comp: JobTalent,
          picto: 'picto_jobprofile',
        },
      ],
      componentLeft: [
        {
          name: '',
          comp: JobResume,
        },
      ],
    };
  },
  methods: {
    parseDate(dateToParse) {
      if (!dateToParse) {
        return 'N/A';
      }
      const tempDateT = dateToParse.split('T');
      const tempDateYMD = tempDateT[0].split('-');
      const finalDate = `${tempDateYMD[2]}/${tempDateYMD[1]}/${tempDateYMD[0]}`;
      return finalDate;
    },
    goTop() {
      const element = document.getElementById('settings-cont');
      this.$nextTick(() => {
        element.scrollTop = 0;
      });
    },
    startScroll() {
      const ps = new PerfectScrollbar('#settings-cont', {
        wheelPropagation: false,
        suppressScrollX: true,
      });
      ps.update();
      this.scrollInit = true;
    },
  },
  mounted() {
    setTimeout(() => {
      this.startScroll();
    }, 500);
  },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.full-width{
  width: 100%;
}

.main-cont{
  height: 92%;
  justify-content: space-between;
  .offer-resume{
    width: calc(30% - 4vh);
    padding-right: 4vh;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;

  }

  .offer-settings{
    width: calc(70% - 4vh);
    padding-left: 4vh;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;

  }
  .content-cont{
    width: calc(100% - 2vh);
    max-height: 75vh;
    position: relative;
    flex-direction: column;
    padding: 1vh;
    justify-content: space-between;
    overflow: hidden;

    .separ-section{
      width: 100%;
      height: 0.1vh;
      background-color: $logo_color;
      margin: 2vh 0;
    }

    .next-section{
      position: relative;
      display: block;
      background-color: #8c28e1;
      color: #FFF;
      transition: background-color 0.3s;
      font-size: 1.4vh;
      padding: 1vh 1.5vh;
      cursor: pointer;
      width: 13%;
      margin-left: 75%;
      border-radius: 5px;
      cursor: pointer;

    }
  }

  .select-group{
    display: flex;
    width: calc(100% - 2vh);
    padding: 1vh;
    flex-direction: row;
    justify-content: start;
    margin-bottom: 1vh;
    .option{
      font-size: 1.8vh;
      font-weight: 600;
      margin-right: 1vw;
      cursor: pointer;
      margin-right: 3vw;
      position: relative;
      .underline{
        position: absolute;
        width: 50%;
        background-color:#8c28e1 ;
        border-radius: 0.25vh;
        margin-top: 1vh;
        height: 4px;
        border-radius: 2px;
        transition: all 0.2s;

      }
      &.selection{
        font-family: "MontserratBold";
      }
    }

}

}
</style>
