<template>
  <div v-if="loaded">
    <div
      class="btn-green greener small radius-small"
      @click="follow"
      v-if="!this.following"
    >
      Follow
    </div>
    <div
      class="btn-green small radius-small"
      @mouseover="changeText($event, 'Unfollow')"
      @mouseleave="changeText($event, 'Following')"
      @click="unfollow($event, 'Follow')"
      v-else
    >
      Following
    </div>
  </div>
</template>

<script>
export default {
  name: 'FollowButton',
  props: ['type', 'accountId'],
  methods: {
    follow() {
      this.$store.dispatch('feed/followList/follow', { accountType: this.type, accountId: this.accountId });
    },
    unfollow(e, text) {
      const targetHover = e.target;
      targetHover.innerHTML = text;
      this.$store.dispatch('feed/followList/unfollow', this.following._id);
    },
    changeText(e, text) {
      const targetHover = e.target;
      targetHover.innerHTML = text;
    },
  },
  computed: {
    following() {
      return this.$store.getters['feed/followList/isFollowing'](this.accountId, this.type);
    },
    loaded() {
      return this.$store.state.feed.followList.loaded;
    },
  },
  mounted() {
    if (!this.$store.state.feed.followList.loaded) {
      this.$store.dispatch('feed/followList/getFollowing');
    }
  },

};
</script>

<style>

</style>
