<template>
  <div id="resume" class="job-resume">
    <div class="radius contentbox box-shadow border-purple">
      <div class="subtitle-page wother">
        <div class="other">
          <div class="profile-pic box-shadow"
            :style="`background-image:url(${companyInfo.companyPicture});`"
            >
          </div>
        </div>
        {{company.name}}
      </div>
      <job-side-display :offer="this.offer" />
    </div>
    <div class="cont-btn margin">
      <div class="btn-green radius-small small" @click="goCompanypage" v-if="this.$route.params.id">
        Company page
      </div>
    </div>
    <div class="radius contentbox box-shadow greyBG">
      <social-share :title="`Apply to '${this.offer.name}' on Quasting`"/>
    </div>
    <div class="postule radius contentbox box-shadow greyBG" v-if="checkAuthType('Talent')" >
      <div class="subtitle-page">
        Application
      </div>
      <ApplyButton v-if="offer._id" :id="offer._id" />
    </div>
    <div class="postule" v-if="checkAuthType('Employee') &&
        this.owner" >
      <offer-options
        :applications="applications"
        :offerId="this.$route.params.id"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ApplyButton from '@/components/TalentComponents/offer/ApplyButton.vue';
import OfferOptions from '@/components/EmployeeComponents/Offer/OfferOptions.vue';
import JobSideDisplay from '@/components/UniversalComponents/Offer/OfferDisplay/JobSideDisplay.vue';
import SocialShare from '@/components/UniversalComponents/SocialMedia/socialShareList.vue';

export default {
  name: 'JobResume',
  components: {
    ApplyButton,
    OfferOptions,
    JobSideDisplay,
    SocialShare,
  },
  computed: {

    ...mapState('offer', {
      company: (state) => state.offerData.company,
      offer: (state) => state.offerData,
    }),
    userData() {
      return this.$store.getters['user/userData'];
    },
  },
  data() {
    return {
      applied: false,
      owner: false,
      applications: [],
      companyInfo: {},
      scrolled: false,
      scrolledBot: false,
      scrolledBotFoot: false,
    };
  },
  methods: {
    parseDate(dateToParse) {
      if (!dateToParse) {
        return 'N/A';
      }
      const tempDateT = dateToParse.split('T');
      const tempDateYMD = tempDateT[0].split('-');
      const finalDate = `${tempDateYMD[2]}/${tempDateYMD[1]}/${tempDateYMD[0]}`;
      return finalDate;
    },
    goCompanypage() {
      this.$router.push(`/company/${this.company._id}`);
    },
    checkOwnership() {
      if (this.checkAuthType('Employee') && this.$route.params.id) {
        this.$api.get(`company/myOffer/${this.$route.params.id}`).then((res) => {
          if (res.data.owner) {
            this.owner = true;
            this.applications = res.data.applications;
          }
        }).catch(() => {});
      }
    },
    handleScroll() {
      let scrolledBotFootVar = false;
      if (document.querySelectorAll('footer').length > 0) {
        scrolledBotFootVar = true;
      }
      if (document.getElementById('cont-others') && document.getElementById('resume')) {
        this.scrolled = window.scrollY > 470 && window.scrollY < (document.getElementById('cont-others').offsetTop - document.getElementById('resume').offsetHeight - 100);
        this.scrolledBot = window.scrollY > (document.getElementById('cont-others').offsetTop - document.getElementById('resume').offsetHeight - 100) && scrolledBotFootVar === false;
      }
    },
    returnID() {
      return this.offer._id;
    },
    getCompanyInfo() {
      this.$api.get(`companyInfo/${this.company._id}`).then((res) => {
        this.companyInfo = res.data;
      });
    },
  },
  mounted() {
    this.checkOwnership();
    this.getCompanyInfo();
  },
  created() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.job-resume{
  .subtitle-page{
    justify-content: flex-start;
    .other{
      .profile-pic{
        height: 5vh;
        width: 5vh;
        border-radius: 50%;
        background-color: $text_color;
        margin-right: 1vh;
        background-size: cover;
        background-position: 50% 50%;
      }
    }
  }
  .contentbox{
    width: calc(100% - 2vh);
    padding: 1vh;
    &.border-purple{
      width: calc(100% - 2.2vh);
      border: 0.1vh solid $logo_color;
    }
    &.greyBG{
      background-color: $new_bg_grey;
    }
  }
  .cont-btn{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    &.margin{
      margin-top: 1vh;
      margin-bottom: 1vh;
      .btn-green{
        margin: 0;
      }
    }
  }
}
.postule{
  margin-top: 2vh;
}
.subtitle-page{
  margin-bottom: 1vh;
}

// .social_global{
//   margin-top: 2vh;
// }

</style>
