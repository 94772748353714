<template>
<div class="social_global">
  <div class="title-share">Share: </div>
  <div class="cont-rs-elem">
    <div class="rs_elem radius-small" v-for="social in socials"
      :key="social.name" >
      <ShareNetwork

        :network="social.name"
        :url="definedUrl"
        :title="title"
        :media="mediaelem"
        :description="description"
        :hashtags="hashtags.join(',')"
      >
        <img :src="social.iconRoute" />
      </ShareNetwork>
    </div>
  </div>
</div>
</template>

<script>
import socials from './socials';

export default {
  name: 'SocialShareList',
  props: {
    url: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Quasting App',
    },
    description: {
      type: String,
      default: 'Check out this from Quasting',
    },
    hashtags: {
      type: Array,
      default: () => [],
    },
    mediaelem: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      definedUrl: this.url,
      socials,
      open: false,
    };
  },

  mounted() {
    if (this.url === '') {
      this.definedUrl = `${window.location.origin}${this.$route.fullPath}`;
    } else {
      this.definedUrl = `${window.location.origin}${this.url}`;
    }
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.social_global {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  &.contentbox {
    width: auto;
    padding: 0.5vh 2vh;
  }
  .title-share {
    margin-right: 1vh;
    font-size: $fnt-16;
  }
  .cont-rs-elem{
    display: flex;
    .rs_elem {
      position: relative;
      width: 1.5vh;
      height: 1.5vh;
      padding: 0.5vh;
      margin-right: 0.5vh;
      background-color: $logo_color;
      &:hover {
        background-color: $green_color;
      }
      &:last-child {
        margin: 0;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        width: 80%;
      }
    }
  }
}

</style>
