<template>
<div class="handle-apply" v-if="this.$store.state.talent.offersRetrieved">
  <div class="btn-green radius-small center"
    v-if="!applied && !sendingRequest" @click="showDialog" >
    Apply
  </div>
  <div class="btn-green radius-small center" v-else-if="sendingRequest">
    Applying
  </div>
  <div class="btn-green reverse radius-small center" v-else @click="showApplication">
    Applied!
  </div>
</div>
</template>

<script>
import CustomApplyModal from '@/components/TalentComponents/offer/customApply.vue';
import DialogModal from '@/components/UniversalComponents/Utilities/DialogModal.vue';
import PublicApplyModal from '@/components/TalentComponents/offer/publicApply.vue';
import OfferProgress from '@/components/TalentComponents/offer/offerProgress.vue';

export default {
  name: 'ApplyButton',
  props: ['id', 'callback'],
  computed: {
    applied() {
      if (this.$store.state.talent.offers === []) {
        return false;
      }
      const ind = this.$store.state.talent.offers.findIndex((offer) => {
        if (offer.offer) {
          return offer.offer._id === this.id;
        }
        return false;
      });
      if (ind > -1) {
        return true;
      }
      return false;
    },
    sendingRequest() {
      return this.$store.state.talent.requestSending;
    },
  },
  methods: {
    showApplication() {
      this.$modal.show(OfferProgress, {
        offer: this.$store.getters['talent/offer'](this.id),
      }, {
        name: 'offer-process',
        width: '80%',
        height: '70vh',
        scrollable: true,
        adaptive: true,
        classes: ['overflow'],
      });
    },
    showCustomApply() {
      this.$modal.show(CustomApplyModal, {
        offerId: this.id,
        cb: () => {
          this.$modal.hide('apply-modal');
        },
      },
      {
        name: 'apply-modal',
        height: '80%',
        scrollable: true,
        width: '70%',
        classes: ['overflow'],
      });
    },
    showPublicApply() {
      this.$modal.show(PublicApplyModal, {
        offerId: this.id,
        cb: () => {
          this.$modal.hide('apply-modal');
        },
      }, {
        name: 'apply-modal',
        height: 'auto',
        scrollable: true,
        width: '50%',
        classes: ['overflow'],
      });
    },
    showDialog() {
      this.$modal.show(
        DialogModal,
        {
          prompt: 'Apply',
          answerA: 'With you public profile',
          answerB: 'With custom information',
          callback: (answer) => {
            this.$modal.hide('dialog-modal');
            if (answer === 'With custom information') {
              this.showCustomApply();
            } else {
              this.showPublicApply();
            }
          },
        },
        {
          name: 'dialog-modal',
          height: 'auto',
          classes: ['overflow'],
        },
      );
    },
  },
  mounted() {
    if (!this.$store.state.talent.offersRetrieved) {
      this.$store.dispatch('talent/getOffers');
    }
  },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.btn-green{
  font-weight: 400;
  &.reverse{
    cursor:none;
    pointer-events: none;
  }
}
</style>
