<template>
<div class="elem-resume cont-info-picto">
  <div class="elem-info">
    <div class="picto"
      v-tooltip.top-center="{
        content: 'Job Type',
        delay: {
          show: 0,
          hide: 0,
        }
      }"
    >
      <img :src="require('@/assets/pictos/picto_contract_darkpurple.svg')">
    </div>
    <div class="texte">{{offer.contract}}</div>
  </div>
  <div class="elem-info">
    <div class="picto"
      v-tooltip.top-center="{
        content: 'Field',
        delay: {
          show: 0,
          hide: 0,
        }
      }"
    >
      <img :src="require('@/assets/pictos/picto_sector_darkpurple.svg')">
    </div>
    <div class="texte">{{offer.sector}}</div>
  </div>
  <div class="elem-info">
    <div class="picto"
      v-tooltip.top-center="{
        content: 'Process',
        delay: {
          show: 0,
          hide: 0,
        }
      }"
    >
      <img :src="require('@/assets/pictos/picto_process_darkpurple.svg')">
    </div>
    <div class="texte">{{offer.numberSteps}} steps process</div>
  </div>
  <div class="elem-info">
    <div class="picto"
      v-tooltip.top-center="{
        content: 'Localization',
        delay: {
          show: 0,
          hide: 0,
        }
      }"
    >
      <img :src="require('@/assets/pictos/picto_localisation_darkpurple.svg')">
    </div>
    <div class="texte">{{offer.location}}</div>
  </div>
  <div class="elem-info"
    v-if="offer.desiredLevelOfStudy">
    <div class="picto"
      v-tooltip.top-center="{
        content: 'Education',
        delay: {
          show: 0,
          hide: 0,
        }
      }"
    >
      <img :src="require('@/assets/pictos/picto_diploma_darkpurple.svg')">
    </div>
    <div class="texte">{{offer.desiredLevelOfStudy}}</div>
  </div>
  <div class="elem-info"
    v-if="offer.salary.salaryType === 'Number' && offer.salary.frequency != 'Unspecified'">
    <div class="picto"
      v-tooltip.top-center="{
        content: 'Salary',
        delay: {
          show: 0,
          hide: 0,
        }
      }"
    >
      <img
        v-if="offer.salary.currency === '€'"
        :src="require('@/assets/pictos/picto_euro_darkpurple.svg')"
      >
      <img
        v-if="offer.salary.currency === '$'"
        :src="require('@/assets/pictos/picto_euro_darkpurple.svg')"
      >
      <img
        v-if="offer.salary.currency === '£'"
        :src="require('@/assets/pictos/picto_euro_darkpurple.svg')"
      >
    </div>
    <div class="texte" v-if="offer.salary.frequency === 'Annual'">
      {{offer.salary.amount}}{{offer.salary.currency}} / year
    </div>
    <div class="texte" v-if="offer.salary.frequency === 'Monthly'">
      {{offer.salary.amount}}{{offer.salary.currency}} / month
    </div>
    <div class="texte" v-if="offer.salary.frequency === 'Daily'">
      {{offer.salary.amount}}{{offer.salary.currency}} / day
    </div>
  </div>
  <div class="elem-info full"
    v-if="offer.salary.salaryType === 'Range' && offer.salary.frequency != 'Unspecified'"
  >
    <div class="picto"
      v-tooltip.top-center="{
        content: 'Salary',
        delay: {
          show: 0,
          hide: 0,
        }
      }"
    >
      <img
        v-if="offer.salary.currency === '€'"
        :src="require('@/assets/pictos/picto_euro_darkpurple.svg')"
      >
      <img
        v-if="offer.salary.currency === '$'"
        :src="require('@/assets/pictos/picto_euro_darkpurple.svg')"
      >
      <img
        v-if="offer.salary.currency === '£'"
        :src="require('@/assets/pictos/picto_euro_darkpurple.svg')"
      >
    </div>
    <div class="texte">
      {{offer.salary.frequency}}:
      {{offer.salary.range.min}}{{offer.salary.currency}}
        -
      {{offer.salary.range.max}}{{offer.salary.currency}}
    </div>
  </div>
  <div class="elem-info" v-if="offer.startDate !== ''">
    <div class="picto"
      v-tooltip.top-center="{
        content: 'Start Date',
        delay: {
          show: 0,
          hide: 0,
        }
      }"
    >
      <img :src="require('@/assets/pictos/picto_calendar_darkpurple.svg')">
    </div>
    <div class="texte">
      {{parseDate(offer.startDate)}}
    </div>
  </div>
  <!-- {{offer}} -->
</div>
</template>

<script>
export default {
  name: 'JobSideDisplay',
  props: ['offer'],
  methods: {
    parseDate(dateToParse) {
      if (!dateToParse) {
        return 'N/A';
      }
      const tempDateT = dateToParse.split('T');
      const tempDateYMD = tempDateT[0].split('-');
      const finalDate = `${tempDateYMD[2]}/${tempDateYMD[1]}/${tempDateYMD[0]}`;
      return finalDate;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.elem-resume{
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .elem-info{
    width: calc(calc(100% - 1vh) / 2);
  }
}

.cont-item{
  margin-bottom: 40px;
  .item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .picto{
      width: 4vh;
      height: 4vh;
    }
  }
}
</style>
