<template>
  <div class="offer-option">
    <div class="option-edit radius contentbox box-shadow greyBG">
      <div
        class="subtitle-page wother"
        :class="hide ? 'no-margin' : ''"
      >
        Offer options
      </div>
      <div
        class="btn-green radius-small center"
        @click="openOfferForm"
      >
        Edit offer
      </div>
    </div>

    <div class="cont-btn margin">
      <div
        class="btn-green radius-small small"
        @click="() => {hide = !hide}"
      >
        {{hide? 'show':'Hide'}}
      </div>
    </div>

    <div class="option-edit radius contentbox box-shadow greyBG"  v-if="!hide">
      <div class="subtitle-page">
        Applicants ({{applications.length}})
      </div>
      <div class="cont-process">

        <div class="content-application-comp" id="dash-application-splide"
          v-if="applications.length > 2">
          <splide class="list-splide-application"
            :options="optionsApplicantMultiple"
          >
            <splide-slide
              class="elem-splide-application"
              v-for="applicant in applications"
              :key="applicant._id"
            >
              <div class="process-entry radius box-shadow"
                @click="() => openProcess(applicant)"
              >
                <user-name-tag :user="applicant.user" :applied="applicant.updatedAt" />
                <div class="line" />
                <process-progress :steps="applicant.process" />
              </div>
            </splide-slide>
          </splide>
        </div>

        <div
          class="process-entry radius box-shadow"
          v-else
          v-for="applicant in applications"
          :key="applicant._id"
          @click="() => openProcess(applicant._id,applicant.user._id)"
        >
            <user-name-tag :user="applicant.user" :applied="applicant.updatedAt" />
            <div class="line" />
            <process-progress :steps="applicant.process" />
        </div>
        <!-- <div
          class="process-entry radius box-shadow"
          v-for="applicant in applications"
          :key="applicant._id"
          @click="() => openProcess(applicant._id,applicant.user._id)"
        >
            <user-name-tag :user="applicant.user" :applied="applicant.updatedAt" />
            <div class="line" />
            <process-progress :steps="applicant.process" />
        </div> -->
      </div>

    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import ProcessProgress from '@/components/EmployeeComponents/JobManagement/processPartials/processProgress.vue';
import ApplicantProcess from '@/components/EmployeeComponents/JobManagement/processPartials/applicantProcess.vue';
import UserNameTag from '@/components/EmployeeComponents/JobManagement/managementPartials/userNameTag.vue';
// import AppliedUser from './_appliedUser.vue';

export default {
  name: 'OfferOptions',
  components: {
    // AppliedUser,
    ProcessProgress,
    UserNameTag,
    Splide,
    SplideSlide,
  },
  props: [
    'applications',
    'offerId',
  ],
  data() {
    return {
      hide: false,
      optionsApplicantMultiple: {
        perPage: 2,
        rewind: true,
        pagination: false,
        drag: false,
        autoWidth: true,
        autoHeight: false,
        autoplay: true,
        direction: 'ttb',
        height: '30vh',
        heightRatio: '30%',
      },
    };
  },
  methods: {
    openOfferForm() {
      this.$store.dispatch('company/setCurrentOfferApplicationId', this.$route.params.id);
      this.$router.push('/job-management');
    },
    openProcess(applicant) {
      console.log(applicant);
      this.$store.dispatch('company/getUpdatedApplicationTimes',
        { offerId: this.offerId, applicationId: applicant }).then(() => {
        this.$modal.show(ApplicantProcess,
          {
            applicant: this.$store.getters['company/applicant'](this.offerId, applicant),
            processComp: ApplicantProcess,
          },
          {
            name: 'applicant-process',
            width: '70%',
            height: '70vh',
            scrollable: true,
            adaptive: true,
            classes: ['overflow'],
          });
      });
    },
  },

};
</script>

<style lang="scss">
@import "@/assets/scss/_splide.scss";
@import "@/assets/scss/_variables.scss";
.offer-option{
  .option-edit{
  }
  .title{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .btn-green{
      padding: 2px 15px;
      font-size: $classic_min_fs;
    }
  }
  .btn-green{
    font-weight: 400;
    margin-top: 1vh;
  }
  .subtitle{
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: $label_min_fs;
  }

  .cont-process{
    width: 100%;
    .process-entry{
      width: calc(100% - 2vh);
      padding: 1vh;
      cursor: pointer;
      background-color: #ffffff;
      margin-bottom: 1vh;
      .line{
        width: 95%;
        left: 2.5%;
        height: 1px;
        background-color: #000;
        position: relative;
        margin-top: 1vh;
      }
      .inner-process{
        .step{
          max-width: 2.1vw;
        }
      }
      &:hover{
        background-color: $logo_color;
        color: #ffffff;
      }
    }
  }
}

.content-application-comp {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 4vh);
  height: 29.2vh;
  overflow: hidden;
  .splide{
    position: unset;
  }
  .elem-skill-talent {
    text-transform: uppercase;
    font-size: 1.6vh;
    font-family: "MontserratBold";
    margin-right: 0.5vh;
    color: #ffffff;
    &:nth-child(even) {
      color: $logo_color;
    }
  }
  .list-splide-application {
    height: 100%;
    .splide__track {
      height: 100%;
      padding: 0;
      .splide__list {
        padding: 0;
      }
    }
  }

  .splide__list{
    width: 100%;
  }

  .elem-splide-application{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
    // text-align: center;
    // color: #ffffff;
    width: 100% !important;
  }

  .splide__arrows {
    z-index: 100;
    position: absolute;
    top: -3.4vh;
    right: 0vh;
    height: 4vh;
    width: 7vh;
    .splide__arrow {
      position: absolute;
      background-color: $green_color;
      width: 3vh;
      height: 3vh;
      border-radius: 50%;
      top: 0 !important;
      transform: none !important;
      border: none;
      opacity: 1;
      box-shadow: 0 0 1.5vh rgba(22, 0, 51, 0.2);
      transition: all 0.2s;
      &:hover {
        background-color: $logo_color;
        svg {
          path {
            fill: $green_color;
          }
        }
      }
      svg {
        position: absolute;
        width: 1.5vh;
        height: unset;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        path {
          fill: $logo_color;
        }
      }
      &.splide__arrow--prev {
        left: 0;
        svg {
          transform: translateX(-50%) translateY(-50%) rotate(90deg);
        }
      }
      &.splide__arrow--next {
        left: 4vh;
        svg {
          transform: translateX(-50%) translateY(-50%) rotate(-90deg);
        }
      }
    }
  }
}

</style>
