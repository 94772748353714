<template>
  <div class="customApply modal">
    <div class="green-circle-button close-button" @click="closeModal">
      <span>X</span>
    </div>
    <div class="content-modal">
      <div class="head-custom-app radius marginB">
        <div class="title-page wother">
          Custom Application
          <div class="other">
            <div @click="apply" class="btn-green radius"> Submit</div>
          </div>
        </div>
        <p>Select elements you want to share for this application:</p>
      </div>

      <div id="modalCustomApply" class="cont-global">
        <expand-section
          :title="'Education Background'"
          v-if="availableAcademicBG.length > 0"
          :startExpanded="true"
        >
          <div
            v-for="entry in availableAcademicBG"
            :key="entry._id"
            @click="() => toggleSelectEntry(entry._id, 'academicBG')"
            :class="{selected: selected.academicBG.indexOf(entry._id) > -1}"
            class="elem-custom radius boxshadow-middle academic"
          >
            <resume-entry
              :type="'academicBG'"
              :editable="false"
              :objectData="entry"
            />
          </div>
        </expand-section>

        <expand-section
          :title="'Professional Background'"
          v-if="availableProfessionalBG.length > 0"
          :startExpanded="true"
        >
          <div
            v-for="entry in availableProfessionalBG"
            :key="entry._id"
            @click="() => toggleSelectEntry(entry._id, 'professionalBG')"
            :class="{selected: selected.professionalBG.indexOf(entry._id) > -1}"
            class="elem-custom radius boxshadow-middle professional"
          >
            <resume-entry
              :type="'professionalBG'"
              :editable="false"
              :objectData="entry"
            />
          </div>
        </expand-section>

        <expand-section
          :title="'Projects'"
          v-if="availableProjects.length > 0"
          :startExpanded="true"
        >
          <div
            v-for="entry in availableProfessionalBG"
            :key="entry._id"
            @click="() => toggleSelectEntry(entry._id, 'projects')"
            :class="{selected: selected.projects.indexOf(entry._id) > -1}"
            class="elem-custom radius boxshadow-middle project"
          >
            <resume-entry
              :type="'projects'"
              :editable="false"
              :objectData="entry"
            />
          </div>
        </expand-section>

        <expand-section
          :title="'Skills'"
          v-if="availableSkills.length > 0"
          :startExpanded="true"
        >
          <div
            v-for="(skill, index) in availableSkills"
            :key="index"
            @click="() => toggleSelectEntry(skill, 'skills')"
            :class="{selected:  selected.skills.indexOf(skill) > -1}"
            class="elem-custom skill"
          >
            <div class="elem_skill radius">
              {{skill}}
            </div>
          </div>
        </expand-section>

        <expand-section
          :title="'Technos'"
          v-if="availableTechnos.length > 0"
          :startExpanded="true"
        >
          <div
            v-for="(techno, index) in availableTechnos"
            :key="index"
            @click="() => toggleSelectEntry(techno, 'technos')"
            :class="{selected:  selected.technos.indexOf(techno) > -1}"
            class="elem-custom techno"
          >
            <div class="elem_techno">
              <img
                v-if="getPictoTechno(techno)"
                :src="require(`@/assets/pictos/${getPictoTechno(techno)}.svg`)"
              >
              <img
                v-else
                :src="require(`@/assets/pictos/technos/unspecified.svg`)"
              >
            </div>
          </div>
        </expand-section>

        <expand-section
          :title="'Posts'"
          v-if="availableProjects.length > 0"
          :startExpanded="true"
          class="posts"
        >
          <!-- <feedCard
            v-for="entry in availablePosts"
            :key="entry._id"
            :elem="entry"
            @click="toggleSelectEntry(entry._id, 'posts')"
            :class="{selected: selected.posts.indexOf(entry._id) > -1}"
            class="elem-custom radius boxshadow-middle post"
          /> -->

          <div
          v-for="entry in availablePosts"
            :key="entry._id"
            @click="() => toggleSelectEntry(entry._id, 'posts')"
            :class="{selected: selected.posts.indexOf(entry._id) > -1}"
            class="elem-custom radius boxshadow-middle post"
          >
            <feedCard
              :elem="entry"
            />
          </div>

          <!-- <div
          v-for="entry in availablePosts"
            :key="entry._id"
            @click="() => toggleSelectEntry(entry._id, 'posts')"
            :class="{selected: selected.posts.indexOf(entry._id) > -1}"
            class="elem-custom radius boxshadow-middle post"
          >
            <Post
              :post="entry"
            ></Post>
          </div> -->
        </expand-section>

        <expand-section
          :title="'Cover Letter'"
          :startExpanded="true"
          class="multiple"
        >
          <p>Why should we choose you?</p>
          <div>
            <wysiwyg class="wysiwyg radius" v-model="motivationalText" />
          </div>
        </expand-section>
      </div>

      <!-- <div class="cont-btn">
        <div @click="apply" class="btn-green radius"> Submit</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import ExpandSection from '@/components/utilityPartials/expandSection.vue';
// import TalentSkills from '@/components/UniversalComponents/TalentView/TalentSkills.vue';
import ResumeEntry from '@/components/TalentComponents/resume/ResumeEntry.vue';
import feedCard from '@/components/UniversalComponents/Search/Results/specificCards/feedCard.vue';
// import Post from '@/components/UniversalComponents/Feed/_post.vue';
import technoList from '@/components/TalentComponents/talentTechnos/talentTechnoList';

export default {
  name: 'ApplyModal',
  components: {
    ExpandSection,
    // TalentSkills,
    ResumeEntry,
    // Post,
    feedCard,
  },
  props: ['offerId', 'cb'],
  data() {
    return {
      selected: {
        skills: [],
        technos: [],
        projects: [],
        academicBG: [],
        professionalBG: [],
        posts: [],
      },
      motivationalText: '',
    };
  },
  computed: {
    availableSkills() {
      return this.$store.getters['talent/skills'];
    },
    availableTechnos() {
      return this.$store.getters['talent/technologies'];
    },
    availableProjects() {
      return this.$store.getters['talent/projects'];
    },
    availableProfessionalBG() {
      return this.$store.getters['talent/professionalBG'];
    },
    availableAcademicBG() {
      return this.$store.getters['talent/academicBG'];
    },
    availablePosts() {
      return this.$store.state.feed.myPosts.myPosts;
    },

  },
  methods: {
    apply() {
      const applicationBody = {
        offerId: this.offerId,
        application: {
          customApplication: this.compileCustomApplication(),
          motivationalText: this.motivationalText,
        },
      };
      this.$store.dispatch('talent/applyToOffer', applicationBody).then((data) => this.cb(data));
    },
    compileCustomApplication() {
      const findAll = (arr, collection) => arr
        .map((entry) => collection.find((collectionEntry) => collectionEntry._id === entry))
        .filter((entry) => entry !== undefined);
      const customApplication = {
        skills: this.selected.skills,
        technologies: this.selected.technos,
        projects: findAll(this.selected.projects, this.availableProjects),
        academicBG: findAll(this.selected.academicBG, this.availableAcademicBG),
        professionalBG: findAll(this.selected.professionalBG, this.availableProfessionalBG),
        posts: findAll(this.selected.posts, this.availablePosts),
      };

      return customApplication;
    },
    toggleSelectEntry(id, type) {
      const index = this.selected[type].indexOf(id);
      if (index > -1) {
        this.selected[type].splice(index, 1);
      } else {
        this.selected[type].push(id);
      }
    },
    getPictoTechno(tech) {
      if (this.findByMatchingProperties(technoList, { text: tech }).length > 0) {
        return this.findByMatchingProperties(technoList, { text: tech })[0].picto;
      }
      return false;
    },
    findByMatchingProperties(set, properties) {
      return set.filter((entry) => Object.keys(properties).every(
        (key) => entry[key] === properties[key],
      ));
    },
    closeModal() {
      this.$modal.hide(this.modalName ? this.modalName : 'apply-modal');
    },
  },
  mounted() {
    if (!this.$store.state.talent.resumeRetrieved) {
      this.$store.dispatch('talent/getTalentInfo');
      this.$store.dispatch('talent/getResume');
    }
    if (!this.$store.getters['feed/myPosts/loaded']) {
      this.$store.dispatch('feed/myPosts/updateMyPosts');
    }
    const ps = new PerfectScrollbar('#modalCustomApply', {
      wheelPropagation: false,
      wheelSpeed: 1,
      // minScrollbarLength: 20,
    });
    ps.update();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.head-custom-app{
  margin-bottom: 2vh;
}

.customApply{
  .ResumeEntry{
    width: calc(100% - 1vh) !important;
    padding: 0.5vh;
    .ap-entry{
      margin: 0 !important;
    }
  }
  .selected{
    background-color: $logo_color !important;
  }
}

.cont-global{
  position: relative;
  padding: 0.5vh;
  height: 64vh;
  overflow: hidden;
}

.expandSection{
  margin-bottom: 2vh;
  .block-settings{
    background-color: $new_bg_grey;
  }
  &.multiple{
    .cont-settings{
      display: flex;
      flex-direction: column;
      p{
        margin-bottom: 1vh;
      }
      > div {
        width: 100%;
      }
    }
  }
  &:last-child{
    margin-bottom: 0;
  }
}

.cont-settings{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  .elem-custom{
    cursor: pointer;
    margin-bottom: 1vh;
    opacity: 0.5;
    transition: all .2s;
    &.wBorder{
      border: 1px solid $border_darkgray;
      box-sizing: border-box;
    }
    &.selected{
      opacity: 1;
      padding: 0;
      border: 0;
    }
    &:hover{
      opacity: 0.8;
    }
    .ap-entry{
      margin-bottom: 0;
    }
    &.academic, &.professional{
      width: calc(calc(100% - 1vh) / 2);
      margin-right: 1vh;
      &:nth-child(2n){
        margin-right: 0;
      }
    }
    &.project, &.post{
      width: calc(calc(100% - 2vh) / 3);
      margin-right: 1vh;
      &:nth-child(3n){
        margin-right: 0;
      }
      .cont-more{
        display: none;
      }
    }
    &.skill{
      margin-right: 1vh;
      padding: 0.5vh;
      border-radius: 1vh;
      .elem_skill{
        margin-bottom: 0;
      }
    }
    &.post{
      width: calc(calc(100% - 5vh) / 3);
      padding: 0.5vh;
      .elem-feed{
        pointer-events: none;
        margin: 0;
        width: 100%;
        .global-param{
          display: none;
        }
        .footer-feed{
          .elem-footer{
            .social_global{
              display: none;
            }
          }
        }
      }
    }
    &.techno{
      width: calc(calc(100% - 13vh) / 14);
      margin-right: 1vh;
      background-color: #ffffff;
      border-radius: 1vh;
      &:nth-child(14n){
        margin-right: 0;
      }
    }
  }

}
.posts{
  .elem-custom{
    margin-right: 0;
    &:nth-child(1n){
      margin-right: 1vh;
    }
    &:nth-child(2n){
      margin-right: 1vh;
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.view-project{
  h4{
    display: block;
    width: 100%;
  }
}
.cont-settings{
  .post{
    .elem-feed{
      // width: 100%;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      margin-bottom: 1.5vh;
      height: 47vh;
      &:nth-child(3n){
        margin-right: 0;
      }
      .head-feed{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: calc(100% - 2vh);
        padding: 1vh;
        border-bottom: 0.4vh solid $new_bg_grey;
        .pic-name{
          display:flex;
          align-items: center;
          .profile-pic{
            height: 5vh;
            width: 5vh;
            background-size: cover;
            background-position: 50% 50%;
            background-color: $logo_color;
            border-radius: 50%;
            border: 0.1vh solid $logo_color;
            overflow: hidden;
          }
          .profile-user{
            margin-left: 1.5vh;
            display: flex;
            flex-direction: column;
            .name-user{
              font-size: 2vh;
              font-family: 'MontserratBold';
            }
            .time-post{
              margin-top: 0.5vh;
              font-size: 1.2vh;
              color: $border_darkgray;
            }
          }
        }
        .btn-green{
          display: none;
        }
      }
      .content-feed{
        padding: 1vh;
        flex: 1;
        .excerp-post{
          margin-bottom: 1vh;
          // max-height: 25vh;
          overflow: hidden;
        }
        .visual-feed{
          width: 100%;
          max-height: 30vh;
          font-size: 0;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
          }
          video{
            width: 100%;
            height: 100%;
          }
        }
      }
      .footer-feed{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1vh;
        .left-footer{
          display: flex;
          .elem-footer{
            .picto{
              width: 2vh;
              img{
                width: 100%;
              }
            }
          }
        }
        .right-footer{
          display: flex;
          .social_global{
            display: none;
          }
        }
      }
    }
  }
}
</style>
