<template>
  <div class="modal">
    <div class="content-modal">
      <div class="head-public-app radius marginB">
        <div class="title-page wother no-margin">
          Public Application
          <div class="other">
            <div @click="apply" class="btn-green radius"> Submit</div>
          </div>
        </div>
      </div>
      <expand-section
        :title="'Cover Letter'"
        :startExpanded="true"
        class="multiple"
      >
        <p>Why should we choose you?</p>
        <div>
          <wysiwyg class="wysiwyg radius" v-model="motivationalText" />
        </div>
      </expand-section>
    </div>
  </div>
</template>

<script>
import ExpandSection from '@/components/utilityPartials/expandSection.vue';

export default {
  name: 'ApplyModal',
  components: {
    ExpandSection,
  },
  props: ['offerId', 'cb'],
  data() {
    return {
      motivationalText: '',
      presentationVideo: '',
    };
  },
  methods: {
    apply() {
      const applicationBody = {
        offerId: this.offerId,
        application: {
          customApplication: false,
          motivationalText: this.motivationalText,
          presentationVideo: this.presentationVideo,
        },
      };
      this.$store.dispatch('talent/applyToOffer', applicationBody).then((data) => this.cb(data));
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
.head-public-app{
  margin-bottom: 2vh;
}
.expandSection{
  &.multiple{
    .cont-settings{
      display: flex;
      flex-direction: column;
      p{
        margin-bottom: 1vh;
      }
      > div {
        width: 100%;
      }
    }
  }
}
</style>
